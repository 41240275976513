import React, { useRef, useState, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AddSponsor from './addSponsor';
import AddSupport from './addSupport';
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import { formerChairmanOption, jiguOption, formerJiguChongjaeOption, memberOptionTypes, gender, clubOption356E } from './selectItems';
import imageCompression from 'browser-image-compression';
import { ChildProps, ClubUserDto, selectDataType } from '../common/dto';
import GroupContext from '../../context/groupContext';
import { BasicBlueButton } from '../common/commonUI';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
        },
        modalMedium: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
            maxHeight: '450px',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

const AddMember356E: React.FC<ChildProps> = (props: ChildProps) => {
    const { register, handleSubmit, watch, errors, getValues, setValue } = useForm();
    const [memberImg, setMemberImg] = useState(logo);
    const [mImg, setmImg] = useState("");
    const [addSponsorModalOpen, setAddSponsorModalOpen] = useState(false);
    const [addSupportModalOpen, setAddSupportModalOpen] = useState(false);
    const [checkSendSMS, setCheckSendSMS] = useState(false);
    const [initDeviceId, setInitDeviceId] = useState(false);

    const [isClubChairmanSlogan, setIsClubChairmanSlogan] = useState(false);
    const [isChongJaeSlogan, setIsChongJaeSlogan] = useState(false);
    const [isDetailInfoNecessay, setIsDetailInfoNecessay] = useState(false);
    const [isBuChongJaeSlogan, setIsBuChongJaeSlogan] = useState(false);

    const [isDropoutMember, setIsDropoutMember] = useState(false);

    const [selectedClubPosition, setSelectedClubPosition] = useState("");
    const [selectedClubPositionVal, setSelectedClubPositionVal] = useState(null);

    const [selectedJiguPosition, setSelectedJiguPosition] = useState("");
    const [selectedJiguPositionVal, setSelectedJiguPositionVal] = useState(0);
    const [selectedDobulePosition, setSelectedDoublePosition] = useState("");

    const [selectedChangedClub, setSelectedChangedClub] = useState<selectDataType>({value:0, label:'전입 클럽 선택'});
    const [allJiguClubs, setAllJiguClubs] = useState<Array<ClubUserDto>>([]);

    const [jiguPositions, setJiguPositions] = useState<Array<selectDataType>>([]);
    const [clubPositions, setClubPositions] = useState<Array<selectDataType>>([]);
    const [doublePositions, setDoublePositions] = useState<Array<selectDataType>>([]);

    const [selectedMemberTypeArr, setSelectedMemberTypeArr] = useState<Array<string>>([]);
    const [selectedGender, setSelectedGender] = useState<selectDataType>({ value: 0, label: '성별 선택' });

    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [selectedGFCheckBox, setSelectedGFCheckBox] = useState("");
    const [selectedTRCheckBox, setSelectedTRCheckBox] = useState("");

    const groupValue = useContext(GroupContext);
    
    const getJiguPositionData = (positionVal:number) => {
        return jiguPositions.filter(each => {
            return each.value === positionVal;
        })[0];
    }

    const getClubPositionData = (position: string) => {
        let selectedClubPosition = clubPositions.filter(each => {
            return each.label === position;
        })[0];
        return selectedClubPosition;
    }

    const getMemberTypeData = (positionVal: number) => {
        return memberOptionTypes.filter(each => {
            return each.value === positionVal;
        })[0];
    }

    useEffect(() => {
        axios.get(comm.SERVER_URL+"/position/jigu/3").then(res => {
            console.log(" jigupositions >> ", res.data);
            setJiguPositions(res.data);
        });

        axios.get(comm.SERVER_URL+"/position/club/3").then(res => {
            setClubPositions(res.data);
        });
        console.log(" groupValue.jiyeokId >> ", props.jiyeok);
        axios.get(comm.SERVER_URL+"/group/jiguclubs/", {params:{id: 3}}).then(res =>{
            console.log(" res @@@@ >> ", res.data);
            setAllJiguClubs(res.data);
        });

        axios.get(comm.SERVER_URL + "/member/", {
            params: {
                id: props.memberId
            }
        }).then(res => {
            console.log(" res.data >> ", res.data);
            if(!res.data){
                setSelectedMemberTypeArr([]);
            }else{
                setSelectedMemberTypeArr(res.data.memberTypeArr);
            }
            
            if(res.data.dropout){
                setIsDropoutMember(true);
            }

            setSelectedClubPosition(res.data.positionClub ? res.data.positionClub : "");
            setSelectedClubPositionVal(res.data.positionClubVal ? res.data.positionClubVal : null);

            setSelectedJiguPosition(res.data.positionJigu ? res.data.positionJigu : "");
            setSelectedJiguPositionVal(res.data.positionJiguVal ? res.data.positionJiguVal : 0);
            setValue("inputName", res.data.name);
            let jiguVal = res.data.positionJiguVal;
            console.log("clubVal >> ", jiguVal);
            if([33 , 47 , 27 , 45].includes(jiguVal)){
               setIsDetailInfoNecessay(true);
               setValue("inputJiguExeDetailInfo", res.data.positionJigu); 
            }

            setValue("inputPositionClub", res.data.positionClub);
            setValue("inputPositionJigu", res.data.positionJigu);
            setValue("inputPositionJiyeok", res.data.positionJiyeok);
            setValue("inputSponsorName", res.data.sponsorName);

            let chairmanVal: Array<number> = res.data.formerChairman;
            let fchValues: Array<selectDataType> = [];

            if (chairmanVal) {
                chairmanVal.forEach(each => {
                    formerChairmanOption.forEach(formerChairman => {
                        if (formerChairman.value === each) {
                            fchValues.push(formerChairman);
                        }
                    });
                });
            }

            if (res.data.positionClubVal == 1) {
                setIsClubChairmanSlogan(true);
            }

            if (res.data.positionJiguVal == 1 || res.data.positionJiguVal == 2) {
                setIsChongJaeSlogan(true);
            }

            if (res.data.positionJiyeokVal == 1 || res.data.positionJiyeokVal == 2) {
                setIsBuChongJaeSlogan(true);
            }

            setValue("inputPositionFreeClub", res.data.positionFreeClub);
            setValue("inputPositionFreeJigu", res.data.positionFreeJigu);
            setValue("inputPositionFreeJiyoek", res.data.positionFreeJiyeok);

            setValue("inputMemberDate", res.data.startDay);
            setValue("inputMemberNo", res.data.memberNo);
            setValue("inputJob", res.data.job);
            setValue("inputAddress", res.data.address);
            setValue("inputMobile", res.data.mobileNum);
            setValue("inputHome", res.data.phoneNumHome);
            setValue("inputBusiness", res.data.phoneNumWork);
            setValue("inputNameEnglish", res.data.englishName);
            setValue("inputEmail", res.data.email);

            setValue("formerChongjae", res.data.formerJiguChongjae);
            setValue("formerChongjang", res.data.formerJiguChongjang);
            setValue("formerEdu", res.data.formerEdu);
            setValue("formerInsa", res.data.formerInsa);

            // setValue("formerChongjaeVal", res.data.formerJiguChongjaeVal);
            // setValue("formerChongjangVal", res.data.formerJiguChongjangVal);
            // setValue("formerEduVal", res.data.formerEduVal);
            // setValue("formerInsaVal", res.data.formerInsaVal);

            setValue("inputReligion", res.data.religion);
            setValue("inputHobby", res.data.hobby);
            setValue("inputFinalSchool", res.data.finalSchool);
            setValue("inputBirthYear", res.data.birthYear);

            let gender = { value: 0, label: "성별 선택" }
            if (res.data.gender == "남자") {
                gender.value = 1;
                gender.label = "남자"
            } else if (res.data.gender == "여자") {
                gender.value = 2;
                gender.label = "여자"
            }

            setSelectedGender(gender);

            if (res.data.positionClubVal == 1 && res.data.slogan) {
                setValue("inputClubChairmanSlogan", res.data.slogan);
            }

            if ((res.data.positionJiguVal == 1 || res.data.positionJiguVal == 2) && res.data.slogan) {
                setValue("inputChongjaeSlogan", res.data.slogan);
            }

            if ((res.data.positionJiyeokVal == 1 || res.data.positionJiyeokVal == 2) && res.data.slogan) {
                setValue("inputBuChongjaeSlogan", res.data.slogan);
            }
            console.log(" >>>>>>> ", res.data);

            // setSelectedJiyeokPosition(res.data.positionJiyeokVal ? res.data.positionJiyeokVal : 0);
            if (props.memberId !== -1) {
                const imgUrl = `https://clublions.s3.ap-northeast-2.amazonaws.com/${res.data.imgName}`;
                if (res.data.imgName) {
                    setMemberImg(imgUrl);
                    setmImg(res.data.imgName);
                } else {
                    setMemberImg(logo);
                    setmImg(logo);
                }
            }
        })
    }, []);

    const onSelectionClubPositionChange = (ev: any) => {
        console.log(" ev >> ", ev);
        if(ev.value == 0){
            setSelectedClubPosition("");
            setSelectedClubPositionVal(null);
        }else{
            setSelectedClubPosition(ev.label);
            setSelectedClubPositionVal(ev.value);
        }
        if (ev.value == 1) {
            setIsClubChairmanSlogan(true);
        } else {
            setIsClubChairmanSlogan(false);
        }
    };

    useEffect(()=>{
        console.log("changeSelectedJiguPosition !! ", selectedJiguPositionVal);
    },[selectedJiguPositionVal])

    const onSelectionJiguPositionChange = (ev: any) => {
        console.log("onChange !!!");
        if(ev.value == 0){
            setSelectedJiguPosition("");
            setSelectedJiguPositionVal(0);
        }else{
            setSelectedJiguPosition(ev.label);
            setSelectedJiguPositionVal(ev.value);
        }

        //특별위원장, 분과위원장, 지역위원장, 지대위원장
        if(ev.value == 33 || ev.value == 47 || ev.value == 27 || ev.value == 45){
            setIsDetailInfoNecessay(true);
        }

        if (ev.value == 1 || ev.value == 2) {
            setIsChongJaeSlogan(true);
        } else {
            setIsChongJaeSlogan(false);
        }
    };


    const onSubmit = (data: any) => {
        const { inputPic, inputName, inputPositionClub, inputPositionJigu, inputJiguExeDetailInfo,
            inputPositionFreeClub, inputPositionFreeJigu,
            inputMemberDate,inputJob, inputAddress, inputMobile, inputHome,
            formerChongjae, formerChongjang, formerEdu, formerInsa,
            inputBusiness, inputEmail, inputNameEnglish, inputMemberNo, inputChongjaeSlogan,
            inputReligion, inputHobby, inputFinalSchool, inputBirthYear,  
            inputBuChongjaeSlogan, inputClubChairmanSlogan, inputGender, inputMemberType, inputSponsorName } = getValues();

            console.log("inputPic >> ", inputPic);   
            console.log("inputPic >> ", inputPic.length);   
            

            let imgName = inputPic.length > 0 ? inputPic[0]?.lastModified + inputPic[0]?.name : null;
   
            let dtoObj = {
                name: inputName,
                positionClub: selectedClubPosition,
                positionClubVal: selectedClubPositionVal,
                positionJigu: isDetailInfoNecessay ? inputJiguExeDetailInfo : selectedJiguPosition,  // 특별위원장, 분과위원장, 지역위원장, 지대위원장 인 경우, inputBox 입력값을 넘김 
                positionJiguVal: selectedJiguPositionVal,   // 지구임원들의 값은 변경 되어야 함 
                positionFreeClub: inputPositionFreeClub,
                positionFreeJigu: inputPositionFreeJigu,
                startDay: inputMemberDate ? inputMemberDate : null,
                job: inputJob,
                address: inputAddress,
                mobileNum: inputMobile,
                phoneNumHome: inputHome.replace(/-/gi, ""),
                phoneNumWork: inputBusiness.replace(/-/gi, ""),
                email: inputEmail,
                englishName: inputNameEnglish,
                memberNo: inputMemberNo,
                belongTo: props.club,
                belongToClub: props.club,
                formerJiguChongjae: formerChongjae,
                formerJiguChongjang: formerChongjang,
                formerEdu: formerEdu,
                formerInsa:formerInsa,
                formerJiguChongjaeVal: 0,
                formerJiguChongjangVal: 0,
                formerEduVal: 0,
                formerInsaVal:0,
                religion:inputReligion,
                hobby:inputHobby,
                finalSchool:inputFinalSchool,
                birthYear:inputBirthYear,
                belongToJigu: props.extraData?.jigu,
                belongToJiyeok: props.extraData?.jiyeok,
                belongToJidae: props.extraData?.jidae,
                imgName: imgName,
                clubName: props.clubName,
                sponsorName:inputSponsorName,
                slogan: inputChongjaeSlogan ? inputChongjaeSlogan : inputBuChongjaeSlogan ? inputBuChongjaeSlogan : inputClubChairmanSlogan ? inputClubChairmanSlogan : "",
                // memberType: selectedMemberType.label,
                memberTypeArr:selectedMemberTypeArr,
                gender: selectedGender.label,
                dropout:'',
                isDropoutConfirm:false,
                initDeviceId:false,
                checkSendSMS:false,
            }
            
        let img_name_detail = "" 
        if(inputPic.length > 0){
          img_name_detail = dtoObj.belongToJigu +"_" + dtoObj.belongToJiyeok + "_" + dtoObj.belongToClub + "_" + dtoObj.clubName+ "_" +dtoObj.name+"_"+inputPic[0]?.lastModified;   
        }
        
        dtoObj.imgName = img_name_detail
        console.log(" dto >> ", dtoObj);  
        console.log(" props >> ", props);

        if (props.memberId === -1) {
            //  AddMember  
            axios.post(comm.SERVER_URL + "/member", dtoObj).then((res) => {
                if (res.data.ok) {
                    if (inputPic.length > 0) {
                        uploadProfilePic(inputPic, img_name_detail);
                    } else {
                        props.fire();
                    }
                } else if (res.data.error === "The user already exists") {
                    alert("이미 등록된 회원입니다.");
                }
            });

        } else {
            console.log(" UPDATE MEMBER !! ");
            //  updateMember
            let imgName = "";
            if (inputPic.length > 0) {
                imgName = img_name_detail;
            } else if (memberImg !== logo) {
                imgName = mImg;
            }

            console.log(" props.memberId >> ", props.memberId);

            let updateDtoObj = {...dtoObj, id:props.memberId};
            updateDtoObj.imgName = imgName;
            updateDtoObj.initDeviceId = initDeviceId;
            updateDtoObj.checkSendSMS = checkSendSMS;
            if(updateDtoObj.memberTypeArr.includes("재입회원")){
                updateDtoObj.dropout = '';
                updateDtoObj.isDropoutConfirm = false;
            }
            console.log(" update dto >> ", updateDtoObj);
            // 전입이 체크된 경우
            if(selectedTRCheckBox == '전입회원'){
                if(selectedChangedClub.value == 0){
                    alert('전입 클럽을 선택해 주세요');
                    return;
                }else{
                    const selectedClub = allJiguClubs.filter((eachClub : ClubUserDto) => {
                        return eachClub.id == selectedChangedClub.value;
                    });
                    console.log("selectedClub >> ", selectedClub);

                    updateDtoObj.belongTo = selectedClub[0].id;
                    updateDtoObj.clubName = selectedClub[0].name;
                    updateDtoObj.belongToJiyeok = selectedClub[0].jiyeok;
                    updateDtoObj.belongToJigu = selectedClub[0].jigu;
                    updateDtoObj.belongToClub = selectedClub[0].id;
                }
            }

            axios.put(comm.SERVER_URL + "/member", updateDtoObj).then((res) => {
                if (res.data.ok) {
                    if (inputPic && inputPic.length > 0) {
                        uploadProfilePic(inputPic, imgName);
                    } else {
                        props.fire();
                    }
                }
            });
        }
    };

    const uploadProfilePic = async (inputPic: Array<any>, imgName: string) => {
        const actualFile = inputPic[0];

        const options = {
            maxSizeMB: 1.99,
        };

        try {
            const compressedFile = await imageCompression(actualFile, options);
            const formBody = new FormData();
            formBody.append("file", compressedFile);
            formBody.append("imgName", imgName);

            axios.post(comm.SERVER_URL + "/member/upload", formBody).then((imgUpRes) => {
                props.fire();
            })
        } catch (ex) {
            console.log(" ex >>> ", ex + "");
        }

    }

    const handleFileInputChange = (ev: any) => {
        let imageFile = ev.target.files[0];
        setMemberImg(URL.createObjectURL(imageFile));
    }

    const fileInputStyle = {
        width: '90px'
    }

    const registerSpon = (ev: any) => {
        console.log("register-spon");
        setAddSponsorModalOpen(true);
    }

    const registerSupport = (ev: any) => {
        console.log("register-support");
        setAddSupportModalOpen(true);
    }

    const AddSponsorTransitionsModal = () => {
        const classes = useStyles();
        const handleOpen = () => {
            setAddSponsorModalOpen(true);
        };
        const handleClose = () => {
            setAddSponsorModalOpen(false);
        };
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex flex-col items-center justify-items-center overflow-scroll"
                open={addSponsorModalOpen}
                onClose={handleClose}
                // disableScrollLock={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addSponsorModalOpen}>
                    <AddSponsor fire={() => {
                        setAddSponsorModalOpen(false)
                    }} memberId={props.memberId ? props.memberId : 0} />
                </Fade>
            </Modal>
        );
    }

    const onClickCheckedSendSMS = (ev: any) => {
        setCheckSendSMS(!checkSendSMS);
    }

    const onInitDeviceId = (ev: any) => {
        setInitDeviceId(!initDeviceId);
    }

    const AddSupportTransitionsModal = () => {
        const classes = useStyles();
        const handleClose = () => {
            setAddSupportModalOpen(false);
        };
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalMedium}
                open={addSupportModalOpen}
                onClose={handleClose}
                // disableScrollLock={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addSupportModalOpen}>
                    <AddSupport fire={() => {
                        setAddSupportModalOpen(false)
                    }} memberId={props.memberId ? props.memberId : 0} />
                </Fade>
            </Modal>
        );
    }

    const closeModal = () => {
        props.fire();
    };

    const checkDisableCheckBox = (checkBoxName:string) => {
        if(checkBoxName == '일반회원'){
            if(selectedGFCheckBox == "가족회원"){
                return true
            }
        }

        if(checkBoxName == "가족회원"){
            if(selectedGFCheckBox == "일반회원"){
                return true
            }
        }

        if(checkBoxName == "재입회원"){
            if(selectedTRCheckBox == "전입회원"){
                return true
            }
        }

        if(checkBoxName == "전입회원"){
            if(selectedTRCheckBox == "재입회원"){
                return true
            }
        }
    }

    const AddHistoryModal = () => {
        const classes = useStyles();
        const handleClose = () => {
            setAddSupportModalOpen(false);
        };
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalMedium}
                open={addSupportModalOpen}
                onClose={handleClose}
                // disableScrollLock={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addSupportModalOpen}>
                    <AddSupport fire={() => {
                        setAddSupportModalOpen(false)
                    }} memberId={props.memberId ? props.memberId : 0} />
                </Fade>
            </Modal>
        );
    }
    
    const registerHistory = (ev:any) => {

    }

    const handleMemberTypeCheckBox = (ev:any) => {
        // console.log(" memberType >>> ", ev.target.value)
        if(ev.target.checked){
            setSelectedMemberTypeArr([...selectedMemberTypeArr, ev.target.name])
            if(ev.target.name == "일반회원" || ev.target.name == "가족회원"){
                setSelectedGFCheckBox(ev.target.name);
            }else if(ev.target.name == "재입회원" || ev.target.name == "전입회원"){
                setSelectedTRCheckBox(ev.target.name);
            }
        }else{
            setSelectedMemberTypeArr(selectedMemberTypeArr?.filter((each :string)  => {
                return ev.target.name != each
            }))
            if(ev.target.name == "일반회원" || ev.target.name == "가족회원"){
                setSelectedGFCheckBox("");
            }else if(ev.target.name == "재입회원" || ev.target.name == "전입회원"){
                setSelectedTRCheckBox("");
            }
        } 
    }

    const rightUpperUX = () => {
        // console.log("checkDisableCheckBox >> ", selectedMemberTypeArr.includes("일반회원"))
        return (
            <div className='flex flex-row mt-4 lg:ml-6 lg:mt-0'>
                <div className="flex flex-col w-36 lg:w-42">
                    <div className={props.memberId != -1 ? "" : "hidden"}>
                        <span>협찬</span>
                        <button type="button" className="mr-8 rounded-md border-green-600 border-solid border-2 border-opacity-60 w-32 h-12
                hover:bg-green-200 mt-2" onClick={registerSpon}>협찬 등록</button>
                    </div>
                    <span className="mt-3">회원 구분</span>
                    <div className='flex flex-col ml-1 items-center mt-1 mr-2'>
                        <div className='flex flex-row items-center'>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("일반회원")} checked={selectedMemberTypeArr?.includes("일반회원")} name="일반회원" className="h-6 w-6 mr-1 text-sm" 
                                onChange={(ev) => handleMemberTypeCheckBox(ev)} />일반
                            </label>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("가족회원")} 
                                checked={selectedMemberTypeArr?.includes("가족회원")} name="가족회원" className="h-6 w-6 ml-4 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />가족
                            </label>
                        </div>
                        <div className='flex flex-row items-center mt-1'>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("재입회원")} 
                                checked={selectedMemberTypeArr?.includes("재입회원")} name="재입회원" className="h-6 w-6 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />재입
                            </label>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("전입회원")} 
                                checked={selectedMemberTypeArr?.includes("전입회원")} name="전입회원" className="h-6 w-6 ml-4 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />전입
                            </label>
                        </div>
                        <div className={selectedTRCheckBox == '전입회원' ? "flex flex-row" : 'hidden'}>
                        <Select
                        value={selectedChangedClub}
                        className="mt-2 w-32 lg:w-48 h-8"
                        name="inputChangedClub"
                        onChange={(ev) => {
                            console.log("ev 22222 >> ", ev)
                            if (ev) {
                                setSelectedChangedClub(ev)
                            } else {
                                setSelectedChangedClub({ value: 0, label: "전입 클럽 선택" })
                            }
                        }}
                        options={allJiguClubs.map(each => {return {value:each.id, label:each.name}})}
                        ref={register}
                    />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-36 lg:w-42 ">
                    <div className={props.memberId != -1 ? "" : "hidden"}>
                        <span>찬조</span>
                        <button type="button" className="mr-8 rounded-md border-green-600 border-solid border-2 border-opacity-60 w-32 h-12
                     hover:bg-green-200 mt-2" onClick={registerSupport}>찬조 등록</button>
                    </div>
                    <span className="mt-3">성별</span>
                    <Select
                        value={selectedGender}
                        className="mt-2 w-32 lg:w-36 h-8"
                        name="inputGender"
                        onChange={(ev) => {
                            console.log("ev 22222 >> ", ev)
                            if (ev) {
                                setSelectedGender(ev)
                            } else {
                                setSelectedGender({ value: 0, label: "성별 선택" })
                            }
                        }}
                        options={gender}
                        ref={register}
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="h-196 w-272 lg:h-180 border-gray-800 border-2 bg-white overflow-auto">
                <div className="relative">
                    <div className="absolute top-3 right-3 " onClick={closeModal}>
                        <CloseIcon />
                    </div>
                </div>
                <div className="flex m-4 lg:m-8 flex-col lg:flex-row">
                    <div className="flex flex-col lg:mr-10">
                        <div className="flex flex-row items-center w-62 lg:justify-between">
                            <span className="w-32">사진</span>
                            <label className='w-22 flex items-center justify-center border-gray-300 border-2 rounded-lg'>사진 선택
                                <input type="file" ref={register} name="inputPic" id="inputPic" accept="image/*" className='hidden' onChange={handleFileInputChange} />
                            </label>
                        </div>
                        <img src={memberImg} className="w-32 h-32 mt-2 lg:w-64 lg:h-64 lg:mt-5" />
                    </div>

                    <div className="flex flex-col">
                        <div className="flex flex-row items-start">
                            <div className="flex flex-col w-32 lg:w-36">
                                <span>이름</span>
                                <input type="text" name="inputName" className="input mt-2 w-40" ref={register} />
                                <span className="mt-2">영문 이름</span>
                                <input type="text" name="inputNameEnglish" className="input mt-2 w-40" ref={register} />
                            </div>

                            <div className="flex flex-col w-32 lg:w-36 ml-10 lg:ml-16">
                                <span>입회일</span>
                                <input type="date" name="inputMemberDate" className="input-date mt-2 w-36" ref={register} />
                                <span className="mt-2">회원 번호</span>
                                <input type="text" name="inputMemberNo" className="input mt-2 w-32 lg:w-36" ref={register} />
                            </div>
                            {windowSize.current[0] >= 1024 ? rightUpperUX() : null}
                        </div>
                        {windowSize.current[0] < 1024 ? rightUpperUX() : null}
                        <div className="flex flex-row items-start mt-6">
                            <div className="flex flex-col w-40 lg:w-44">
                                <span className='font-semibold'>클럽(3역) 직책</span>
                                <Select
                                    value={getClubPositionData(selectedClubPosition)}
                                    className="mt-2"
                                    name="inputPositionClub"
                                    onChange={onSelectionClubPositionChange}
                                    options={clubPositions}
                                    ref={register}
                                />
                                <div className="flex flex-row items-center mt-2">
                                    <input type="text" name="inputPositionFreeClub" ref={register} className="w-40 lg:w-44 focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors" />
                                </div>
                                <div className={isClubChairmanSlogan ? "flex flex-col justify-center mt-2" : "hidden"}>
                                    <span className='text-sm'>회장 슬로건</span>
                                    <input type="text" name="inputClubChairmanSlogan" placeholder="회장 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors w-36 lg:w-44" ></input>
                                </div>
                            </div>
                            <div className="flex flex-col ml-4 lg:ml-8 w-40 lg:w-44">
                                <span className='font-semibold'>지구 직책</span>
                                <Select
                                    value={getJiguPositionData(selectedJiguPositionVal)}
                                    className="mt-2"
                                    name="inputPositionJigu"
                                    onChange={onSelectionJiguPositionChange}
                                    options={jiguPositions}
                                    ref={register}
                                />
                                <div className='mt-2'></div>
                                <div className={isChongJaeSlogan ? "flex flex-row items-center" : "hidden"}>
                                    <input type="text" name="inputChongjaeSlogan" placeholder="총재 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors lg:w-54" ></input>
                                </div>
                                <div className={isDetailInfoNecessay ? "flex flex-row items-center" : "hidden"}>
                                    <input type="text" name="inputJiguExeDetailInfo" placeholder="세부 직책 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors lg:w-54" ></input>
                                </div>
                                <button type="button" className="mr-8 rounded-md border-green-600 border-solid border-2 border-opacity-60 w-32 h-12
                hover:bg-green-200 mt-2" onClick={registerHistory}>과거 직책 등록</button>
                            </div>
                            <div className="flex flex-col ml-4 lg:ml-8 w-40 lg:w-44">
                                <span className='font-semibold'>추천인</span>
                                <div className="flex flex-row items-center">
                                    <input type="text" name="inputSponsorName" placeholder="추천인(스폰서)" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors lg:w-54" ></input>
                                </div>
                     
                            </div>
                        </div>
                        <div className="flex flex-col mt-4 w-40 lg:w-44">
                                {/* <span className='font-semibold'>중복 직책</span> */}
                                <div className='flex flex-row'>
                                    <div className='flex flex-col'>
                                        <span className=''>총재협의회</span>
                                        <input className='input w-48' type='text' name='formerChongjae' ref={register}></input>
                                        <div className='mt-2'></div>
                                        {/* <div className={isChongJaeSlogan ? "flex flex-row items-center" : "hidden"}>
                                            <input type="text" name="inputChongjaeSlogan" placeholder="총재 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors lg:w-54" ></input>
                                        </div> */}
                                    </div>
                                    <div className='flex flex-col ml-4'>
                                        <span className=''>총장협의회</span>
                                        <input className='input w-48' type='text' name='formerChongjang' ref={register}></input>
                                        <div className='mt-2'></div>
                                    </div>
                                    <div className='flex flex-col ml-4'>
                                        <span className=''>교육 연수원</span>
                                        <input className='input w-48' type='text' name='formerEdu' ref={register}></input>
                                        <div className='mt-2'></div>
                                    </div>
                                    <div className='flex flex-col ml-4'>
                                        <span className=''>인사위원회</span>
                                        <input className='input w-48' type='text' name='formerInsa' ref={register}></input>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                                <div className='mt-1 text-base w-164'>* 회원의 위의 네 직책 변경이 있으시면, 변경 후 관리자(010-2349-3105)로 문자 부탁드립니다.</div>

                            </div>

                        <div className="flex flex-row items-center mt-5 lg:mt-10">
                            <div className="flex flex-col w-28 lg:w-1/2">
                                <span>직업</span>
                                <input type="text" name="inputJob" className="input mt-2" ref={register} />
                            </div>
                            <div className="flex flex-col w-full ml-6">
                                <span>주소</span>
                                <input type="text" name="inputAddress" className="input mt-2 w-60 lg:w-128" ref={register} />
                            </div>
                        </div>
                        <div className="mt-6 font-bold">연락처</div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-3">핸드폰</span>
                                <input type="number" name="inputMobile" placeholder="숫자만 예)01090111234" className="input w-44 lg:ml-3 lg:w-64" ref={register} required />
                            </div>

                            <div className='flex flex-col ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-5">자택</span>
                                <input type="number" name="inputHome" placeholder="지역번호 포함 숫자만 예) 028825959" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>

                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20">사업장</span>
                                <input type="number" name="inputBusiness" placeholder="지역번호 포함 숫자만 예) 031-5345959" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-5">이메일</span>
                                <input type="text" name="inputEmail" className="input ml-3 w-44 lg:w-64" ref={register} />
                            </div>
                        </div>
                        <div className="mt-6 font-bold">기타 정보</div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20">최종학교</span>
                                <input type="text" name="inputFinalSchool" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 ml-3 lg:ml-5">생일</span>
                                <input type="text" name="inputBirthYear" className="input ml-3 w-44 lg:w-64" ref={register} />
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20">취미</span>
                                <input type="text" name="inputHobby" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 ml-3 lg:ml-5">종교</span>
                                <input type="text" name="inputReligion" className="input ml-3 w-44 lg:w-64" ref={register} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-center'>
                    <label className={props.memberId != -1 ? "flex flex-row items-center justify-center mr-3 lg:mr-14" : "hidden"}>
                        <input type="checkbox" name="checkSendSMS" value="checkSendSMS" checked={checkSendSMS} className="w-6 h-6 mr-2 " ref={register} onClick={onClickCheckedSendSMS} />앱설치 안내 문자
                    </label>
                    <label className={props.memberId != -1 ? "flex flex-row items-center justify-center mr-8" : "hidden"}>
                        <input type="checkbox" name="initDeviceId" value="initDeviceId" checked={initDeviceId} className="w-6 h-6 mr-2 " ref={register} onClick={onInitDeviceId} />디바이스 초기화
                    </label>
                </div>
                <div className="flex flex-row justify-center items-center h-32">
                    <button type="submit" disabled={isDropoutMember && groupValue.authLevel < 2} className="lg:mr-8 mr-3 rounded-md lg:rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-32 h-10 lg:w-48 lg:h-16 hover:bg-blue-200">{props.memberId === -1 ? "회원 추가" : "회원 수정"}</button>
                    <button type="button" className="lg:ml-8 ml-3 rounded-md lg:rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-32 h-10 lg:w-48 lg:h-16 hover:bg-red-200" onClick={props.fire}>취소</button>
                </div>
            </form>
            {AddSponsorTransitionsModal()}
            {AddSupportTransitionsModal()}
            {AddHistoryModal()}
        </div>
    )
}

export default AddMember356E;